.sigContainer {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: rgb(255, 255, 255, 0.1);
  border: 1px solid rgb(148, 148, 148);
  position: relative;
  text-align: center;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.signature-pad-buttons {
  text-align: center;
}

.sigContainerWaterMark {
  color: #e5e5e5;
  margin: 0 auto;
  font-size: 50px;
  position: absolute;
  z-index: -99;
  top: 50%;
  left: 100px;
  margin-top: -30px;
}
