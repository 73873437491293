.confirmation-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-popup {
  display: flex;
  width: 320px;
  flex-direction: column;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 20px;
}

.confirmation-info {
  text-align: center;
  padding: 1rem;
  font-size: 20px;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #000;
}

.btn-confirmation {
  width: 160px;
  text-align: center;
  padding: 1rem;
  font-size: 20px;
  border: 0;
  background-color: rgba(255, 255, 255, 0);
}
