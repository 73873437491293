* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  display: flex;
  list-style: none;
  justify-content: center;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

li a {
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.toggle-switch {
  line-height: 34px;
}

.toggle-switch span {
  padding-left: 3px;
}

.list {
  max-height: 270px;
  overflow: auto;
  flex-direction: column;
}

.list-h {
  max-height: 400px;
  overflow: auto;
  flex-direction: column;
  background-color: rgb(228, 228, 228);
}

.list-item {
  margin: 0 auto;
  width: 90%;
  line-height: 3rem;
  border-bottom: 1px solid #000;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.flex-sp-btwn {
  display: flex;
  justify-content: space-between;
}

.flex-sp-arnd {
  display: flex;
  justify-content: space-around;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.btn:active {
  transform: scale(0.95);
  box-shadow: inset;
}

.container {
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.container-t {
  width: 85vw;
  margin: 0 auto;
}

/* weighbridge docket list on tablet */
.wb-list-title {
  background-color: rgb(230, 230, 230);
  line-height: 55px;
  display: flex;
  justify-content: space-between;
}

.wb-list-content {
  line-height: 50vh;
  height: 50vh;
  text-align: center;
}

.wb-list-content h3 {
  vertical-align: middle;
}

.wb-list-title h3,
a {
  margin: 0 10px;
}

.wb-list {
  margin: 0 auto;
  grid-column: 1/5;
  width: 85vw;
  overflow: auto;
  max-height: 75vh;
}

.wb-list-item-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
  border-bottom: 1px solid #000;
}

.wb-list-item-grid-gallery {
  grid-column: 1/3;
  grid-row: 1/3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px 0;
}

.wb-list-item-grid-image {
  width: 30%;
  text-align: center;
  flex-direction: column;
}

.wb-list-item-grid-image img {
  max-width: 100%;
  max-height: 100%;
}

.wb-list-item-grid-desc {
  grid-column: 3/5;
  grid-row: 1/3;
  padding: 5px 0;
  display: grid;
}

.wb-list-item-grid-name {
  line-height: 50px;
  font-size: 40px;
  grid-column: 1/3;
  grid-row: 1;
  padding: 0 10px;
  text-align: left;
}

.wb-list-item-grid-remark {
  line-height: 50px;
  font-size: 30px;
  padding: 0 10px;
  text-align: left;
  grid-column: 1/3;
  grid-row: 2;
}

.wb-d-title {
  padding: 2.5% 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wb-d-title h3 {
  margin: 20px 0;
}

.wb-d {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 72vh;
}

.wb-d-l {
  grid-column: 1;
  border-right: 1px solid rgb(100, 100, 100);
}

.wb-d-r {
  display: block;
  grid-column: 2;
  border-left: 1px solid rgb(100, 100, 100);
}

.wb-d-gallery {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 220px;
}

.wb-d-signature {
  height: 200px;
}

.wb-d-nav {
  width: 85vw;
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-around;
}

.wb-d-list {
  margin: 5% 0;
}

.wb-d-list div {
  margin: 10px 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.alert {
  width: 100%;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 0;
  font-size: 14px;
}

.bg-danger {
  background-color: red;
  color: #fff;
}

.bg-warning {
  background-color: #ffee00;
  color: #000584;
}

.bg-success {
  background-color: #0dd609;
  color: #fff;
}

.logo {
  margin: 0 auto;
}

.login-form {
  width: 250px;
  margin: 0 auto;
}

.input-text,
.input-checkbox {
  margin-bottom: 15px;
}

.input-label {
  line-height: 35px;
}

.input-group {
  margin: 5px auto;
  width: 200px;
}

.input-fix {
  border-radius: 8px;
  border: 0.5px solid #979797;
  box-shadow: inset 0 0 3px 1px rgb(163, 163, 163);
  box-sizing: border-box;
  line-height: 35px;

  background-color: #f2f2f2;
  width: 200px;
  padding: 0 40px 0 90px;
}

.input-fix-left {
  text-align: left;
  position: relative;
  margin-right: -95px;
  width: 90px;
  float: left;
  line-height: 35px;
  padding-left: 5px;
}

.input-fix-right {
  position: relative;
  margin-left: -40px;
  width: 40px;
  float: right;
  line-height: 35px;
}

.input-text {
  border-radius: 8px;
  border: 0.5px solid #979797;
  box-shadow: inset 0 0 3px 1px rgb(163, 163, 163);
  box-sizing: border-box;
  line-height: 35px;
  font-size: 16px;
  padding: 3px 5px;
  background-color: #f2f2f2;
  width: 200px;
}

.btn {
  line-height: 35px;
  font-size: 18px;
  padding: 3px 3px;
  margin: 5px auto;
  border: 0;
  border-radius: 8px;
  width: 200px;
}

.btn-prod {
  background-color: #ffd362;
  color: #000584;
}

.btn-mat {
  background-color: #3a95ff;
  color: #fff;
}

.btn-order {
  background-color: #0dd609;
  color: #fff;
}
.btn-danger {
  background-color: #ff0000;
  color: #fff;
}

.btn-supplier-selector {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.btn-cam {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  font-size: 40px;
}

.btn-sq {
  line-height: 60px;
  width: 60px;
  font-size: 45px;
  border: none;
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
}

nav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  align-items: center;
}

nav a {
  color: #000;
}

nav .button-group .material-icons,
.nav-logo h4 {
  line-height: 60px;
}

.landing-buttons {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.material,
.production,
.bookin,
.orders {
  display: flex;
  height: 85vh;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.spreader {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
}

.supplier-grid {
  width: 328px !important ;
  background-color: rgba(177, 177, 177, 0.61);
}

.grid-item {
  box-sizing: border-box;
  text-align: center;
}

.list-item-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
  border-bottom: 1px solid #000;
}

.list-item-grid-gallery {
  grid-column: 1/5;
  grid-row: 1/3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px 0;
}

.list-item-grid-image {
  width: 30%;
}

.list-item-grid-image img {
  width: 100%;
}

.list-item-grid-desc {
  grid-column: 1/5;
  grid-row: 3;
  padding: 5px 0;
  display: grid;
}

.list-item-grid-name {
  grid-column: 1/3;
  grid-row: 1;
  padding: 0 10px;
  text-align: right;
}

.list-item-grid-remark {
  padding: 0 10px;
  text-align: left;
  grid-column: 3/5;
  grid-row: 1;
}

.img-gallery {
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}

.img-gallery img {
  width: 100%;
}

.img-gallery-item {
  padding: 0.5rem;
  width: 20%;
}

.pdf-gallery-item {
  padding: 0.5rem;
}

.process-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px auto;
  width: 200px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #dbdbdb;
}

.process-images button {
  margin: 0;
}

.photo-container {
  background-color: #000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img-center img {
  object-fit: contain;
}

.photo-nav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  align-items: center;
  position: sticky;
  bottom: 0;
}

.qr-scanner {
  height: 100%;
}

.qr-scanner div {
  height: 100%;
}

.wb-cont-list {
  max-height: 240px;
  overflow: scroll;
}
