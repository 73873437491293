.header-menu-item {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.notification {
  background-color: rgb(255, 70, 70);
  color: #fff;
}
