.modal {
  position: fixed;
  z-index: 500;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  margin: 0 auto;
  left: 0;
  /* top: 0; */
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.fs-modal {
  top: 0;
}

.solid-bg-modal {
  background-color: #fff;
}
